import ALink from '@a3/chameleon/src/components/basic/ALink';
import { ButtonS } from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useState } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pageUrls from 'constants/pagesUrls';

import Cross from 'components/HeroBlock/components/Cross';
import s from 'components/HeroBlock/components/MobileMenu/MobileMenu.module.scss';
import { headerMenuData } from 'components/HeroBlock/components/data';
import type { MobileMenuTypes } from 'components/HeroBlock/components/types';
import { ImagesPathEnum } from 'components/HeroBlock/components/types';

import { handleScrollAndToggle } from 'utils/handleScrollAndToggle';

const MobileMenu: FC<MobileMenuTypes> = ({
  className,
  isBlack = false,
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const { clickAnalytics } = useAnalytics();

  const toggleMenu = (): void => {
    clickAnalytics(gtmEvents.clickMobileMenu);
    setIsOpen(prevOpen => !prevOpen);
  };

  return (
    <div className={cn(s.wrapper, className)} {...restProps}>
      <Cross isOpen={isOpen} onClick={toggleMenu} isBlack={isBlack} />
      {isOpen && (
        <div className={s.background}>
          <div className={s.fadeMenu}>
            <style global jsx>
              {`
                body {
                  overflow: hidden;
                }
              `}
            </style>
            <div className={s.header}>
              <ALink href={pageUrls.home} className={s.logo}>
                <Image
                  src={ImagesPathEnum.blackLogo}
                  width={164}
                  height={32}
                  priority
                  alt="Логотип сервиса"
                />
              </ALink>
            </div>

            <div className={s.content}>
              <ul className={s.wrapperMenu}>
                {headerMenuData.map(({ title, classBlock, analyticsEvent }) => (
                  <ButtonS
                    key={title}
                    Tag="li"
                    className={cn(s.item, { [s.itemBlack]: isBlack })}
                    onClick={(): void =>
                      handleScrollAndToggle({
                        router,
                        classBlock,
                        isOpen,
                        setIsOpen,
                        clickAnalytics,
                        analyticsEvent,
                      })
                    }
                  >
                    {title}
                  </ButtonS>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
