export const defaultTitle =
  'Мосплатежи – Приложение для оплаты ЖКХ в Москве – скачать приложение коммунальных услуг (ЖКУ) Москвы бесплатно';
export const defaultDescription =
  'Единое приложение для оплаты коммунальных услуг в Москве, удобный интерфейс, все счета в одном приложении, проверка задолженности и отправка показаний счетчиков за электроэнергию, газ и воду. Официальное приложение ЖКХ Москвы – Мосплатежи. Доступно для устройств Android и iOS.';
export const defaultOgDescription = '';

export const jsonLd = {
  '@context': 'http://schema.org',
  '@type': 'Corporation',
  name: '',
  description: '',
  logo: '',
  url: '',
  email: 'mailto:',
  sameAs: [],
};
