import { gtmEvents } from 'constants/gtmEvent';

export const headerMenuData = [
  {
    title: 'Почему мы?',
    classBlock: '.js-advantages',
    analyticsEvent: gtmEvents.clickHeaderAdvantages,
  },
  {
    title: 'Скачать приложение',
    classBlock: '.js-download-application',
    analyticsEvent: gtmEvents.clickHeaderDownloadApplication,
  },
  {
    title: 'Как это работает',
    classBlock: '.js-unique-proposition',
    analyticsEvent: gtmEvents.clickUniqueProposition,
  },
  {
    title: 'Отзывы',
    classBlock: '.js-review-slider',
    analyticsEvent: gtmEvents.clickReviewSlider,
  },
  {
    title: 'Вопросы и ответы',
    classBlock: '.js-faq',
    analyticsEvent: gtmEvents.clickHeaderFaq,
  },
];
