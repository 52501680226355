/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import {
  defaultDescription,
  defaultOgDescription,
  defaultTitle,
  jsonLd,
} from './constants';

interface Props {
  title?: string;
  description?: string;
}

export const HtmlHead = (props: Props): JSX.Element => {
  const { title = defaultTitle, description = defaultDescription } = props;
  const router = useRouter();
  const canonicalUrl = `https://mosplatezhi.com${router.pathname}`;

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link key="canonical" rel="canonical" href={canonicalUrl} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content="moscow-payments" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      <meta httpEquiv="msthemecompatible" content="no" />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
      />

      <>
        <link rel="icon" type="image/x-icon" href="/favicons/favicon.ico" />
        <link rel="icon" sizes="192x192" href="/favicons/favicon.ico" />
        <link rel="apple-touch-icon" href="/favicons/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/favicon.ico"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon.ico"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon.ico"
        />
        <link rel="manifest" href="/favicons/site.webmanifest" />
      </>
      <meta name="format-detection" content="telephone=no" />
      <meta name="msapplication-config" content="/favicons/browserconfig.xml" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image" content="https://mosplatezhi.com/og/og.png" />
      <meta
        name="twitter:description"
        content={description || defaultOgDescription}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta key="og:url" property="og:url" content={canonicalUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta
        property="og:description"
        content={description ?? defaultOgDescription}
      />
      <meta property="og:image" content="https://mosplatezhi.com/og/og.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content={title} />
      <meta property="og:locale" content="ru_RU" />
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />
    </Head>
  );
};
