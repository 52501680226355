const pageUrls = {
  home: '/',
  security: '/security/',
  mobileAppStore:
    'https://redirect.appmetrica.yandex.com/serve/965403996481160793',
  mobileGooglePlay:
    'https://redirect.appmetrica.yandex.com/serve/965409846209905295',
  downloadStore:
    'https://redirect.appmetrica.yandex.com/serve/1181847993700149095',

  //пример с параметром
  // offer: (variant?: keyof typeof variants): string => {
  //   if (variant) {
  //     return `/user/offer/?variant=${variant}`;
  //   }
  //
  //   return '/user/offer/';
  // },˚
};

export default pageUrls;
