interface GtmClickEventType {
  event: 'mos_click';
  sourceScreen: string;
  name: string;
  businessType: 'b2c';
  SBN: '1';
}

export type ClickEvent = Pick<GtmClickEventType, 'sourceScreen' | 'name'>;

export interface ScrollEvent {
  percent: number;
}

export type ClickAnalyticsFunctionType = (event: ClickEvent) => void;
export type ScrollAnalyticsFunctionType = (event: ScrollEvent) => void;

export const gtmEvents = {
  clickHeaderLogo: {
    name: 'МосПлатежи',
    sourceScreen: 'header',
  },
  clickHeaderAdvantages: {
    name: 'Почему мы?',
    sourceScreen: 'header',
  },
  clickHeaderDownloadApplication: {
    name: 'Скачать приложение',
    sourceScreen: 'header',
  },
  clickUniqueProposition: {
    name: 'Как это работает',
    sourceScreen: 'header',
  },
  clickReviewSlider: {
    name: 'Отзывы',
    sourceScreen: 'header',
  },
  clickHeaderFaq: {
    name: 'Вопросы и Ответы',
    sourceScreen: 'header',
  },
  clickMobileMenu: {
    name: 'Меню',
    sourceScreen: 'header',
  },
  clickMainAppStore: {
    name: 'Скачать в AppStore',
    sourceScreen: 'Main',
  },
  clickMainGooglePlay: {
    name: 'Скачать в Google Play',
    sourceScreen: 'Main',
  },
  clickDownloadStoreMain: {
    name: 'Скачать',
    sourceScreen: 'Main',
  },
  clickDownloadStoreAdvantages: {
    name: 'Скачать',
    sourceScreen: 'Advantages',
  },
  clickDownloadAppStore: {
    name: 'Скачать в AppStore',
    sourceScreen: 'mobile_block',
  },
  clickDownloadGooglePlay: {
    name: 'Скачать в Google Play',
    sourceScreen: 'mobile_block',
  },
  clickDownloadStore: {
    name: 'Скачать',
    sourceScreen: 'qr_block',
  },
  clickReviewArrowNext: {
    name: 'Еще',
    sourceScreen: 'reviews',
  },
  clickReviewArrowPrev: {
    name: 'Назад',
    sourceScreen: 'reviews',
  },
  clickAllQuestionsFaq: {
    name: 'Все вопросы',
    sourceScreen: 'faq',
  },
  clickDownloadStoreFaq: {
    name: 'Скачать приложение',
    sourceScreen: 'faq',
  },
  clickFooterLogo: {
    name: 'МосПлатежи',
    sourceScreen: 'footer',
  },
  clickSecurityFooter: {
    name: 'Безопасность',
    sourceScreen: 'footer',
  },
  clickGoMainFrom404: {
    name: 'Вернуться на главную',
    sourceScreen: '404',
  },
};
