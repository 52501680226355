import type { DivProps } from 'app.types';
import cn from 'classnames';
import React from 'react';
import type { FC, ReactNode } from 'react';

import s from './ContentContainer.module.scss';

interface ContentContainerTypes extends DivProps {
  children?: ReactNode;
}

const ContentContainer: FC<ContentContainerTypes> = ({
  children,
  className,
  ...restProps
}) => {
  return (
    <div className={cn(s.wrapper, className)} {...restProps}>
      {children}
    </div>
  );
};

export default ContentContainer;
