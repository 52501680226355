import { ButtonS } from '@a3/chameleon/src/components/basic/Typography';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import { headerMenuData } from 'components/HeroBlock/components/data';
import type { DesktopMenuTypes } from 'components/HeroBlock/components/types';

import { handleScrollAndToggle } from 'utils/handleScrollAndToggle';

import s from './DesktopMenu.module.scss';

const DesktopMenu: FC<DesktopMenuTypes> = ({ className, isBlack = false }) => {
  const router = useRouter();
  const { clickAnalytics } = useAnalytics();

  return (
    <ul className={cn(s.wrapper, className)}>
      {headerMenuData.map(({ title, classBlock, analyticsEvent }) => (
        <ButtonS
          key={title}
          Tag="li"
          className={cn(s.item, { [s.itemBlack]: isBlack })}
          onClick={(): void =>
            handleScrollAndToggle({
              router,
              classBlock,
              clickAnalytics,
              analyticsEvent,
            })
          }
        >
          {title}
        </ButtonS>
      ))}
    </ul>
  );
};

export default DesktopMenu;
