import cn from 'classnames';
import Image from 'next/image';
import type { FC } from 'react';

import type { CrossTypes } from 'components/HeroBlock/components/types';
import { ImagesPathEnum } from 'components/HeroBlock/components/types';

import s from './Cross.module.scss';

const Cross: FC<CrossTypes> = ({ onClick, isOpen, isBlack }) => {
  return (
    <div
      className={cn(s.cross, {
        [s.openedMenu]: isOpen,
      })}
      onClick={onClick}
    >
      <Image
        src={
          isOpen
            ? ImagesPathEnum.cross
            : isBlack
            ? ImagesPathEnum.blackBurger
            : ImagesPathEnum.burger
        }
        width={40}
        height={40}
        alt="Бургер меню"
      />
    </div>
  );
};

export default Cross;
