import ALink from '@a3/chameleon/src/components/basic/ALink';
import Typograf from '@a3/chameleon/src/components/basic/Typograf';
import {
  Caption1,
  Subtitle1,
  Subtitle2,
} from '@a3/chameleon/src/components/basic/Typography';
import type { DivProps } from 'app.types';
import cn from 'classnames';
import useAnalytics from 'hooks/useAnalytics';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import { gtmEvents } from 'constants/gtmEvent';
import pageUrls from 'constants/pagesUrls';

import s from './Footer.module.scss';

interface FooterTypes extends DivProps {
  className?: string;
}

const Footer: FC<FooterTypes> = ({ className }) => {
  const router = useRouter();
  const { clickAnalytics } = useAnalytics();

  return (
    <Typograf>
      <footer className={cn('grid', s.wrapperFooter, className)}>
        <div className={s.logoWrapper}>
          <ALink
            className={s.imgLogo}
            href={pageUrls.home}
            onClick={(): void => clickAnalytics(gtmEvents.clickFooterLogo)}
          >
            <Image
              src="/img/Footer/logoFooter.svg"
              alt="Логотип сервиса"
              width={164}
              height={32}
            />
          </ALink>
          <div className={s.wrapperPhone}>
            <Subtitle1>
              <ALink className={s.phoneNumber} href="tel:88001003900">
                Горячая линия 8 800 100 39 00
              </ALink>
            </Subtitle1>
            <Caption1 className={s.descriptionPhone}>
              Звонок по РФ бесплатный
            </Caption1>
          </div>
        </div>
        <Subtitle2 className={cn(s.wrapperSecurityLink, s.linksWrapper)}>
          <ALink
            className={cn(s.link, {
              [s.linkCurrent]: router.asPath.includes(pageUrls.security),
            })}
            href={pageUrls.security}
            onClick={(): void => clickAnalytics(gtmEvents.clickSecurityFooter)}
          >
            Безопасность
          </ALink>
        </Subtitle2>

        <Caption1 className={s.descriptionCopyright}>
          Приложение “МосПлатежи” является разработкой компании ООО «Платёжный
          сервис А3», ОГРН 1107746155164. Услугу по переводу денежных средств с
          использованием Платёжного сервиса А3 оказывает ПАО «Промсвязьбанк»
          генеральная лицензия Банка России 3251 от 17.12.2014
        </Caption1>
      </footer>
    </Typograf>
  );
};

export default Footer;
