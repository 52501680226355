import type { DivProps } from 'app.types';

import { gtmEvents } from 'constants/gtmEvent';

export enum ImagesPathEnum {
  qrCode = '/img/HeroBlock/QR.png',
  appStore = '/img/HeroBlock/appStore.svg',
  googlePlay = '/img/HeroBlock/googlePlay.svg',
  whiteLogo = '/img/HeroBlock/white-logo.svg',
  blackLogo = '/img/HeroBlock/black-logo.svg',
  cross = '/img/HeroBlock/cross.svg',
  burger = '/img/HeroBlock/burger.svg',
  blackBurger = '/img/Header/black-burger.svg',
}

export interface CrossTypes extends DivProps {
  onClick: () => void;
  isOpen?: boolean;
  isBlack?: boolean;
}

export interface DesktopMenuTypes extends DivProps {
  isBlack?: boolean;
}

export interface MobileMenuTypes extends DivProps {
  isBlack?: boolean;
}

export interface HeroItemTypes extends DivProps {
  title: string;
  image: string;
  alt: string;
}

export interface HeroItemsDataTypes {
  title: string;
  image: string;
  alt: string;
}

export type NavigationMenuTypes = DivProps;

export const classBlock = '.js-download-application';
export const analyticsEvent = gtmEvents.clickDownloadStoreMain;
